var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('q-layout',{attrs:{"view":"hHh lpr fFR"}},[_c('q-page-container',[_c('div',{staticClass:"row q-px-lg q-pt-lg full-height"},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"kanban-container row fit",on:{"click":function($event){_vm.showDrawerKanban = false}}},[_c('kanban-board',{ref:"kanban",staticClass:"test",attrs:{"stages":_vm.stages,"blocks":_vm.companies},on:{"update-block":_vm.updateStatus,"dragstart":_vm.dragstart}},[_vm._l((_vm.stages),function(stage){
var _obj;
return [_c('div',{key:("stage-" + stage),ref:"stage",refInFor:true,class:( _obj = {}, _obj[("stage-" + stage)] = true, _obj ),attrs:{"slot":stage},slot:stage},[_c('div',{staticClass:"label-wrapper"},[_c('span',{staticClass:"label medium dark type-bold atlas-kanban-list-label"},[_vm._v(_vm._s(_vm.getStageName(stage)))])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
                    _vm.emptyTile(stage) && _vm.companiesKanbanStats !== 'receiving'
                  ),expression:"\n                    emptyTile(stage) && companiesKanbanStats !== 'receiving'\n                  "}],class:{ 'empty-tile': _vm.emptyTile(stage) }}),_c('q-inner-loading',{staticClass:"kanban-column--loading-overlay",staticStyle:{"height":"100%"},style:(!!_vm.currentCompanyId ? 'z-index: -1' : 'z-index: 1'),attrs:{"showing":_vm.companiesKanbanStats === 'receiving' ||
                    _vm.loadingStage(stage)}},[_c('q-spinner-dots',{attrs:{"color":"primary","size":"40px"}})],1)],1)]}),_vm._l((_vm.blocks),function(block){return _c('div',{key:block.id,ref:"item",refInFor:true,staticClass:"row items-center",attrs:{"slot":block.id},on:{"click":function($event){return _vm.goToCompanyDetail(block.id)}},slot:block.id},[_c('div',{staticClass:"column justify-center q-pa-sm company-kanban-card",attrs:{"data-status":block.status}},[_c('div',{staticClass:"col"},[_c('div',{staticClass:"row justify-center items-center"},[_c('div',{staticClass:"q-mr-sm col-auto items-center"},[_c('q-avatar',{staticStyle:{"background-color":"rgb(173, 195, 237)"},attrs:{"size":"30px","text-color":"white","icon":"mdi-cart-outline"}})],1),(block.title && block.title.length >= 55)?_c('div',{staticClass:"col label medium dark text-weight-bold items-center text-left",staticStyle:{"font-size":"12px !important"}},[_vm._v("\n                      "+_vm._s(block.title.substr(0, 55))+"...\n                      "),_c('q-tooltip',{attrs:{"content-style":"font-size:14px; background-color:#9EB3DC; min-width:180px; padding:.8rem;"}},[_vm._v(_vm._s(block.title))])],1):_c('div',{staticClass:"col label medium dark text-weight-bold items-center text-left",staticStyle:{"font-size":"12px !important"}},[_vm._v("\n                      "+_vm._s(block.title)+"\n                    ")])])]),_c('div',{staticClass:"col text-grey-9 q-pr-xs q-pl-xs q-mt-sm",staticStyle:{"font-size":"11px"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-auto"},[_vm._v(_vm._s(block.address.city)+",")]),_c('div',{staticClass:"col text-left q-ml-sm"},[_vm._v("\n                      "+_vm._s(block.address.state)+"\n                    ")])])]),_c('div',{staticClass:"col text-grey-9 q-px-xs q-mt-sm"},[_c('div',{staticClass:"row"},[_c('span',{staticClass:"q-mr-xs company-badges",class:{
                        'icon-company-target-active': _vm.handleBadges(
                          block,
                          'active_company'
                        ),
                        'icon-company-target-inactive': !_vm.handleBadges(
                          block,
                          'active_company'
                        ),
                      }},[_c('q-tooltip',{attrs:{"content-style":"font-size:14px; background-color:#9EB3DC; min-width:180px; padding:.8rem;","anchor":"top middle","self":"bottom middle"}},[_c('strong',[_vm._v("\n                          "+_vm._s(_vm.handleBadges(block, 'active_company')
                              ? 'Empresa ativa'
                              : 'Empresa inativa')+"\n                        ")])])],1),_c('span',{staticClass:"icon-phone q-mr-xs",class:{
                        'badges-opacity': !_vm.handleBadges(block, 'phones'),
                      }},[_c('q-tooltip',{attrs:{"content-style":"font-size:14px; background-color:#9EB3DC; min-width:180px; padding:.8rem;","anchor":"top middle","self":"bottom middle"}},[_c('strong',[_vm._v("\n                          "+_vm._s(_vm.handleBadges(block, 'phones')
                              ? 'Possui telefones'
                              : 'Não possui telefones')+"\n                        ")])])],1),_c('span',{staticClass:"icon-emails q-mr-xs",class:{
                        'badges-opacity': !_vm.handleBadges(block, 'emails'),
                      },staticStyle:{"font-size":"12px","margin-top":"2px"}},[_c('q-tooltip',{attrs:{"content-style":"font-size:14px; background-color:#9EB3DC; min-width:180px; padding:.8rem;","anchor":"top middle","self":"bottom middle"}},[_c('strong',[_vm._v("\n                          "+_vm._s(_vm.handleBadges(block, 'emails')
                              ? 'Possui e-mails'
                              : 'Não possui e-mails')+"\n                        ")])])],1),_c('span',{staticClass:"icon-company-profile q-mr-xs",class:{
                        'badges-opacity': !_vm.handleBadges(block, 'full_qsa'),
                      }},[_c('q-tooltip',{attrs:{"content-style":"font-size:14px; background-color:#9EB3DC; min-width:180px; padding:.8rem;","anchor":"top middle","self":"bottom middle"}},[_c('strong',[_vm._v("\n                          "+_vm._s(_vm.handleBadges(block, 'full_qsa')
                              ? 'Possui quadro societário'
                              : 'Não possui quadro societário')+"\n                        ")])])],1),_c('span',{staticClass:"icon-company-employees q-mr-xs",class:{
                        'badges-opacity': !_vm.handleBadges(
                          block,
                          'decision_maker'
                        ),
                      }},[_c('q-tooltip',{attrs:{"content-style":"font-size:14px; background-color:#9EB3DC; min-width:180px; padding:.8rem;","anchor":"top middle","self":"bottom middle"}},[_c('strong',[_vm._v("\n                          "+_vm._s(_vm.handleBadges(block, 'decision_maker')
                              ? 'Possui dados de decisores'
                              : 'Não possui dados de decisores')+"\n                        ")])])],1),_c('span',{staticClass:"icon-headquarters q-mr-xs",class:{
                        'badges-opacity': !_vm.handleBadges(
                          block,
                          'has_practice'
                        ),
                      }},[_c('span',{staticClass:"path1"}),_c('span',{staticClass:"path2"}),_c('span',{staticClass:"path3"}),_c('span',{staticClass:"path4"}),_c('span',{staticClass:"path5"}),_c('span',{staticClass:"path6"}),_c('span',{staticClass:"path7"}),_c('span',{staticClass:"path8"}),_c('span',{staticClass:"path9"}),_c('span',{staticClass:"path10"}),_c('span',{staticClass:"path11"}),_c('span',{staticClass:"path12"}),_c('span',{staticClass:"path13"}),_c('q-tooltip',{attrs:{"content-style":"font-size:14px; background-color:#9EB3DC; min-width:180px; padding:.8rem;","anchor":"top middle","self":"bottom middle"}},[_c('strong',[_vm._v("\n                          "+_vm._s(_vm.handleBadges(block, 'has_practice')
                              ? 'Possui dados de práticas da empresa'
                              : 'Não possui dados de práticas da empresa')+"\n                        ")])])],1),_c('span',{staticClass:"q-mr-xs"},[_c('q-icon',{staticClass:"q-mb-sm",class:{
                          'badges-opacity': !_vm.handleBadges(
                            block,
                            'financial_reports'
                          ),
                        },attrs:{"name":"img:statics/images/copy.svg"}}),_c('q-tooltip',{attrs:{"content-style":"font-size:14px; background-color:#9EB3DC; min-width:180px; padding:.8rem;","anchor":"top middle","self":"bottom middle"}},[_c('strong',[_vm._v("\n                          "+_vm._s(_vm.handleBadges(block, 'financial_reports')
                              ? 'Possui relatórios financeiros'
                              : 'Não possui relatórios financeiros')+"\n                        ")])])],1),_c('span',{staticClass:"icon-company-globe q-mr-xs",class:{
                        'badges-opacity': !_vm.handleBadges(block, 'website'),
                      }},[_c('q-tooltip',{attrs:{"content-style":"font-size:14px; background-color:#9EB3DC; min-width:180px; padding:.8rem;","anchor":"top middle","self":"bottom middle"}},[_c('strong',[_vm._v("\n                          "+_vm._s(_vm.handleBadges(block, 'website')
                              ? 'Possui dados de website'
                              : 'Não possui dados de website')+"\n                        ")])])],1)])])])])})],2)],1)]),_c('q-drawer',{staticClass:"nav-kanban",attrs:{"width":_vm.navbarWidth,"side":"right","behavior":"desktop","bordered":""},model:{value:(_vm.showDrawerKanban),callback:function ($$v) {_vm.showDrawerKanban=$$v},expression:"showDrawerKanban"}},[_c('q-btn',{staticClass:"kanban-drawer-btn--close",attrs:{"flat":"","round":"","dense":"","icon":"close","text-color":"grey-6"},on:{"click":function($event){_vm.showDrawerKanban = false}}}),_c('div',{staticClass:"droppable",on:{"mouseenter":function($event){_vm.showDrawerKanban = !_vm.showDrawerKanban}}},[_c('span',{attrs:{"id":"slider-opener"}},[_c('div',{staticClass:"slider-opener-icon-wrapper"},[_c('span',{staticClass:"icon-trash font-18"}),_c('span',{staticClass:"notification-badge"},[_vm._v(_vm._s(_vm.handleDiscardedTotal()))])])])])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }